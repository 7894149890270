@import '_mixin';
@import '_variables';

@import '_mobile/_partial/index-page-mobile';
@import '_mobile/_partial/post-page-mobile';
@import '_mobile/_partial/_post/writing-enhance-mobile';
@import '_mobile/_partial/_sidebar/sidebar-tags-mobile';

// ========== common ========== //
.container {
  flex-direction: column-reverse;
  margin: 0 auto;
  width: 100%;
  padding: 1rem 0 0 0;
  align-items: center;
}

.profile {
  margin: 3rem auto 0.5rem auto;
  padding: 0 0;
  .profile-avatar {
    width: 60%;
    margin: 0 auto;
  }
  .profile-social {
    display: none;
  }
  .friends {
    text-align: center;
    div {
      text-align: center;
    }
  }
  .profile-link-item {
    text-align: center;
  }
}

.toc-wrapper {
  display: none;
}

.footer {
  padding-top: 0;
}

.post-page {
  padding-bottom: 0;
}

.post-paginator {
  .nextTitle,
  .prevTitle {
    font-size: 1rem;
  }

  .nextSlogan,
  .prevSlogan {
    font-size: 1.2rem;
  }
}

.main {
  width: 92%;
  min-width: 0;
  margin: 0;
}

// prevent blink when touch link
* {
  -webkit-tap-highlight-color: transparent;
}

// ========== intro ========== //
.home-body {
  .site-intro-meta {
    width: 100%;
  }
  .intro-title {
    font-size: 3rem;
    line-height: 3.4rem;
    max-width: 80%;
  }
  .intro-subtitle {
    font-size: 1.5rem;
    margin-top: 0.5rem;
    white-space: normal;
    max-width: 90%;
  }
}

.about-body,
.post-body {
  .site-intro-meta {
    min-width: 0;
    width: calc(95% - 2rem);
  }
  .intro-title {
    font-size: 2.6rem;
    line-height: 3rem;
    max-width: 100%;
  }
  .intro-subtitle {
    font-size: 1.5rem;
    white-space: normal;
    max-width: 100%;
  }
  .main {
    width: 95%;
  }
}

// ========== header ========== //
header {
  &.header {
    height: $header-height-mobile;
  }

  &.header-mobile {
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(4px);
  }

  .banner {
    .post-title {
      display: none;
    }

    .blog-title {
      line-height: 2rem;
    }
  }

  .home-link {
    right: 1rem;
    line-height: 2rem;
  }

  .header-sidebar-menu {
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
  }

  .header-sidebar-menu-rounded {
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
  }
}

// ========== fixed buttons ========== //
.footer-fixed-btn {
  display: none;
}

// ========== content ========== //
.post-paginator {
  li {
    max-width: 45%;
  }

  .previous,
  .next {
    float: none;
  }
}

.article-entry {
  blockquote {
    padding: 1rem;
  }
}

.about-page {
  .article-entry {
    margin-bottom: 2rem;
  }
}

// ========== sidebar ========== //
// $sidebar-width: 400px;
.sidebar {
  width: calc(min(400px, 70vw));
}

.wrapper-sidebar-active {
  transform: translate3d(calc(min(400px, 70vw)), 0, 0);
}

header.header-sidebar-active {
  transform: translate3d(calc(min(400px, 70vw)), 0, 0);
}

.footer-fixed-sidebar-active {
  transform: translate3d(calc(min(400px, 70vw)), 0, 0);
}

.tab-name {
  font-size: 1.2rem;
}

.sidebar-archive {
  -webkit-overflow-scrolling: touch;
}

.total-archive {
  font-size: 1.4rem;
}

.archive-year {
  font-size: 1.2rem;
  margin: 0.5rem 0 0.5rem 0;
}

.sidebar-tags {
  -webkit-overflow-scrolling: touch;
}

// ========== comment ========== //
.post-comment {
  margin: 1.5rem 0 2rem 0;
}

// ========== footer ========== //
.footer {
  margin-top: 0;
  .social {
    width: 200px;
    line-height: 2rem;
    min-width: unset;
    @include social($scaleSize: 0.5, $spanMargin: 0.2rem);
  }
}

// ========== note ========== //
.note {
  padding: 0.5rem 1rem;
}
