// ========== main ========== //
.anchorjs-archer {
  display: none;
}

// ========== content ========== //
.article-entry {
  padding: 0.25rem 1rem;
}

// ========== license ========== //
.license-wrapper {
  padding: 0.25rem 1rem;
}
